import React from "react"
import PropTypes from "prop-types"

const BlogHero = ({image, creditName, creditLink}) => {
	if (!image) return (<div/>);

	return (
		<div className='container'>
			<div className='blog_hero' style={{ backgroundImage: 'url('+image+')' }}/>
			<p className='blog_hero_credit'>{creditName} <a href={creditLink}>{creditLink}</a></p>
		</div>
	);
}

BlogHero.propTypes = {
	image: PropTypes.string,
	creditName: PropTypes.string,
	creditLink: PropTypes.string
}

export default BlogHero;
