import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BlogHero from "../components/blog-hero"

export default function Template({
  data,
}) {
  const seoKeys = [`tristan`,`farmer`];
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  const image = frontmatter.image && frontmatter.image.childImageSharp.fixed;
  const imageCredit = frontmatter.imageCredit && frontmatter.imageCredit.split(',');
  seoKeys.concat(frontmatter.seo.split(' '));
  return (
	<Layout>
		<SEO url={frontmatter.path} title={frontmatter.title} description={frontmatter.description} keywords={seoKeys} image={image || data.file.childImageSharp.fixed}/>
			<BlogHero image={image && image.src} creditName={imageCredit && imageCredit[0]} creditLink={imageCredit && imageCredit[1]}/>
			<section className='section'>
				<div className='container'>
					<div className='content'>
						<h1 className="is-size-1">{frontmatter.title}</h1>
						<hr />
						<div dangerouslySetInnerHTML={{ __html: html }} />
					</div>
				</div>
			</section>

	</Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        description
        seo
		imageCredit
		image {
		  childImageSharp {
			fixed(width: 1200) {
			  src
			  width
			  height
			}
		  }
		}
      }
    }
	file(relativePath: { eq: "tristan2020.jpg"}) {
	  childImageSharp {
		fixed(width: 600) {
		  src
		  width
		  height
        }
      }
    }
  }
`
